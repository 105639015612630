import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import HeroIcon from '@components/atoms/hero-icon';
import HeroSection from '@components/molecules/hero-section';
import heroImage from '@assets/raios.png';
import { useScreenSize } from '@hooks/useScreenSize';
import style from './style.module.css';

export function HeroContainer({ isHomePath }: { isHomePath?: boolean }) {
  const isSmallScreen = useScreenSize();
  const heroBannerSection = twMerge(
    isSmallScreen ? 'min-w-fit py-8 mx-auto flex flex-col gap-5' : 'pt-[6.44rem] pb-[6.56rem] pl-20',
  );
  const heroBannerTitle = twMerge(
    isSmallScreen
      ? 'text-center text-heading-xLarge leading-[2.8rem] -mt-4 -ml-8 px-4 w-[28rem]'
      : 'text-left text-display-small leading-[4.5rem]',
    style.HeroBannerTitle,
  );
  const heroBannerParagraph = twMerge(
    isSmallScreen
      ? 'w-[23.8125rem] text-neutral-50 text-center text-sm font-light leading-normal'
      : 'mt-4 mb-[1.69rem] text-paragraph-medium text-left max-w-[38rem]',
    style.HeroBannerParagraph,
  );
  const bannerStyle = twMerge(isSmallScreen ? 'hidden' : 'block', style.BannerStyle);

  return (
    <HeroSection
      imgPath={heroImage}
      imgHeight={706}
      imgWidth={779}
      imgAlt="Prévia do sistema da Clarke num smartphone com fundo de raio verde"
      aria-hidden="true"
      imgExtraClasses={bannerStyle}
      isHomePath={isHomePath}
    >
      <div className={heroBannerSection}>
        <h1 className={heroBannerTitle}>
          O caminho mais eficiente entre a <span className="text-brand-primary-0">sua empresa</span> e o{' '}
          <span className="text-brand-primary-0">mercado livre </span> de energia
        </h1>
        <p className={heroBannerParagraph}>
          A Clarke descomplica e potencializa o mercado livre de energia para sua empresa. Te conectamos às melhores
          oportunidades de preços e fornecedores, transformando custo em economia com um atendimento personalizado e
          muita tecnologia.
        </p>
        <Link to="/mercado-livre-de-energia" className={isSmallScreen ? 'hidden' : ''}>
          <span className=" mt-6 text-white text-center text-sm font-bold leading-normal underline flex gap-4 hover:text-brand-primary-0 ">
            Saber mais sobre o Mercado Livre de Energia{' '}
            <HeroIcon type="solid" icon="ArrowRightIcon" className="w-5 h-5 text-white" />
          </span>
        </Link>
      </div>
    </HeroSection>
  );
}
