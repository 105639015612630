import { useScreenSize } from '@hooks/useScreenSize';
import { FormComponentHeroSection } from '@components/atoms/home/form-hero';
import { twMerge } from 'tailwind-merge';
import style from './style.module.css';

export function HeroModalComponent() {
  const isSmallScreen = useScreenSize();

  const formContainerStyle = isSmallScreen
    ? 'bg-neutral-50 w-full rounded-[1.25rem]'
    : 'items-center bg-neutral-50 py-8 rounded-[1.25rem] lg:w-[25.8125rem] lg:h-[29.5rem] absolute top-1/2 left-56 screen-desktop:left-64 2xl:left-40 transform -translate-x-1/2 -translate-y-1/2';
  const formStyle = ' lg:w-[25.8125rem] lg:h-[29.5rem] items-center gap-2 lg:-mt-16 pr-1';
  return (
    <div className={twMerge(formContainerStyle, 'overflow-y-auto overflow-x-hidden', style.customScrollbar)}>
      <FormComponentHeroSection formStyle={formStyle} />
    </div>
  );
}
