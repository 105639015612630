import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';
import HeroIcon from '@components/atoms/hero-icon';

import style from '@components/molecules/customers-pains/style.module.css';

const CustomersPains = () => {
  const isSmallScreen = useScreenSize();
  const CustomersPainsContainer = twMerge(isSmallScreen ? 'px-4' : 'px-20 pb-20', style.CustomersPainsContainer);
  const CustomersPainsTitle = twMerge(
    isSmallScreen ? 'mb-4 text-heading-medium' : 'mb-20 text-heading-xxLarge',
    'font-bold',
  );
  const CustomersPainsList = twMerge(
    isSmallScreen ? 'flex-col gap-5  mb-10' : 'flex-row gap-12 mb-0',
    style.CustomersPainsList,
  );
  const IconBlock = twMerge(isSmallScreen ? 'h-12 w-12' : 'h-20 w-20', style.IconBlock);
  const IconStyle = twMerge(isSmallScreen ? 'w-6 h-6' : 'w-14 h-14');
  const Card = twMerge(isSmallScreen ? 'w-full border-b border-solid border-gray' : 'w-1/3', style.Card);
  const CardTitle = twMerge(isSmallScreen ? 'text-paragraph-large' : 'text-heading-small', style.CardTitle);
  const CardDescription = twMerge(
    isSmallScreen ? 'text-paragraph-medium' : 'text-paragraph-large',
    style.CardDescription,
  );
  const CardLink = twMerge(isSmallScreen ? 'text-paragraph-medium' : 'text-paragraph-large', style.CardLink);

  return (
    <section className={style.CustomersPainsSection}>
      <div className={CustomersPainsContainer}>
        <div className={CustomersPainsTitle}>
          <h2 className={isSmallScreen ? 'w-full' : 'w-[36rem]'}>
            Sua empresa já enfrentou esses 
            <span className="text-palette-orange-30"> problemas </span> com energia?
          </h2>
          <p className="font-light text-heading-xSmall mt-3">
            A Clarke <span className="font-bold"> entende </span> as principais queixas dos empresários sobre
            fornecimento de energia.
          </p>
        </div>
        <div className={CustomersPainsList}>
          <div className={Card}>
            <div className={IconBlock}>
              <div className={IconStyle}>
                <HeroIcon type="solid" icon="CurrencyDollarIcon" />
              </div>
            </div>
            <h3 className={CardTitle}>Energia Cara</h3>
            <p className={CardDescription}>O Brasil tem a 2ª conta de luz mais cara do mundo.</p>
            <br />
            <p className={CardLink}>
              Fonte:{' '}
              <span className="cursor-pointer text-palette-orange-30">
                <a
                  href="https://einvestidor.estadao.com.br/comportamento/brasil-segunda-conta-luz-cara-mundo/"
                  target="_blank"
                  className="underline"
                  rel="noopener noreferrer nofollow"
                >
                  Estadão
                </a>
              </span>
              .
            </p>
          </div>
          <div className={Card}>
            <div className={IconBlock}>
              <div className={IconStyle}>
                <HeroIcon type="solid" icon="UserMinusIcon" />
              </div>
            </div>
            <h3 className={CardTitle}>Atendimento Ruim</h3>
            <p className={CardDescription}>
              As empresas de energia elétrica aparecem apenas a partir da posição 87 no Ranking nacional de atendimento
              ao cliente.
            </p>
            <br />
            <p className={CardLink}>
              Fonte:{' '}
              <span className="cursor-pointer text-palette-orange-30">
                <a href="https://ibrc.com.br/ranking/" target="_blank" rel="nofollow" className="underline">
                  IRBC
                </a>
              </span>
              .
            </p>
          </div>
          <div className={Card}>
            <div className={IconBlock}>
              <div className={IconStyle}>
                <HeroIcon type="solid" icon="ArrowDownCircleIcon" />
              </div>
            </div>
            <h3 className={CardTitle}>Relação passiva com a energia</h3>
            <p className={CardDescription}>
              Mais de R$ 35 bilhões de reais são desperdiçados todo ano porque as empresas não conhecem o mercado livre
              de energia.
            </p>
            <br />
            <p className={CardLink}>
              Fonte:{' '}
              <span className="cursor-pointer text-palette-orange-30">
                <a href="https://abraceel.com.br/wp-content/uploads/post/2024/04/Boletim-Anual-da-Energia-Livre-Abraceel-2023.pdf" target="_blank" rel="nofollow" className="underline">
                ABRACEEL
                </a>
              </span>
              .
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomersPains;
