import React from 'react';
import heroImage from '@assets/raios full.png';
import { HeroModalComponent } from '@components/atoms/home/modal';
import style from './style.module.css';

interface HeroSectionProps {
  children: React.ReactNode;
  imgPath: string;
  imgAlt: string;
  imgExtraClasses?: string;
  imgHeight?: number;
  imgWidth?: number;
  imgContent?: string;
  imgContentClasses?: string;
  isHomePath?: boolean;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  children,
  imgPath,
  imgAlt,
  imgExtraClasses = '',
  imgWidth,
  imgHeight,
  isHomePath = false,
}) => {
  const isFullSizeScreen = window.innerWidth > 1680;
  return (
    <section className={`pt-28 w-full bg-gradient-custom-10 overflow-hidden`} aria-label="Hero Section">
      <div className={style.HeroSectionMainTextWrapper}>
        {children}
        <div className="2xl:ml-20 relative w-full overflow-y-clip flex justify-center items-center">
          {isFullSizeScreen && isHomePath ? (
            <img src={heroImage} className="min-w-[47.5rem] min-h-[56.25rem] ml-28 absolute object-cover" />
          ) : (
            <img
              src={imgPath}
              alt={imgAlt}
              width={imgWidth}
              height={imgHeight}
              className={imgExtraClasses}
              aria-label={imgAlt}
            />
          )}
          <div className="z-20 p-5 w-full lg:w-auto">{isHomePath && <HeroModalComponent />}</div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
