import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Button, ButtonProps, Theme } from '@radix-ui/themes';
import { Responsive } from '@radix-ui/themes/dist/cjs/props/index.js';

import { IconName, IconType } from '@src/components/atoms/hero-icon';
import HeroIcon from '@src/components/atoms/hero-icon';
import LoadingIcon from '@components/atoms/load-icon';

import style from './style.module.css';

export type Variant =
  | 'primary'
  | 'primaryCustom'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'iconPrimary'
  | 'iconSecondary'
  | 'iconTertiary'
  | 'iconQuaternary';
export type IconPosition = 'none' | 'left' | 'right' | 'custom';
export type ButtonScreen = 'mobile' | 'desktop';

export interface ButtonComponentProps extends ButtonProps {
  kind: Variant;
  id?: string;
  icon?: IconName;
  label?: string;
  iconPosition?: IconPosition;
  size?: Responsive<'1' | '2' | '3' | '4'>;
  screen?: ButtonScreen;
  customAttrs?: Record<string, string>;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  iconType?: IconType;
  iconStyles?: string;
  iconSize?: string;
  labelIcon?: string;
  labelSize?: string;
}

const BUTTON_COLORS = {
  primary: style.primaryStyle,
  primaryCustom: style.primaryStyleCustom,
  secondary: style.secondaryStyle,
  tertiary: style.tertiaryStyle,
  quaternary: style.quaternaryStyle,
  iconPrimary: style.iconPrimaryStyle,
  iconSecondary: style.iconSecondaryStyle,
  iconTertiary: style.iconTertiaryStyle,
  iconQuaternary: style.iconQuaternaryStyle,
};

const BUTTON_style = {
  mobile: {
    minWidth: 'w-full',
    badgeIconSize: 'w-[0.9375rem] min-h-[0.9375rem]',
  },
  desktop: {
    badgeIconSize: 'w-[1.125rem] min-h-[1.125rem]',
  },
};

const ButtonComponent = React.forwardRef<HTMLButtonElement, ButtonComponentProps>(
  (
    {
      kind,
      label,
      icon,
      iconPosition,
      iconType,
      labelIcon,
      labelSize,
      iconStyles,
      iconSize = 'w-6 h-6',
      size,
      screen,
      className,
      onClick,
      loading,
      disabled,
      ...attrs
    },
    ref,
  ) => {
    const btnStyle = BUTTON_COLORS[kind];
    const widthBtn = screen === 'mobile' ? BUTTON_style.mobile.minWidth : '';
    const buttonClasses = twMerge(
      style.btnBehaviour,
      size === '1'
        ? 'p-4 justify-center '
        : size === '2'
          ? 'py-3 px-2 justify-center '
          : size === '3'
            ? 'py-4 px-8 justify-center'
            : size === '4'
              ? ' p-4 justify-between'
              : 'w-[3.375rem] min-h-[3.375rem] justify-center',
      widthBtn,
      btnStyle,
      className,
    );
    const iconContainerClasses = twMerge(iconStyles, style.btnStandard);

    return (
      <Theme>
        <Button
          ref={ref}
          role="button"
          aria-label={label || 'Button'}
          aria-disabled={disabled}
          onClick={onClick}
          className={buttonClasses ?? className}
          disabled={disabled}
          {...attrs}
        >
          {loading ? (
            <div className="flex gap-2 justify-center items-center">
              <LoadingIcon />
              <p key={2}>Carregando...</p>
            </div>
          ) : (
            <>
              {iconPosition === 'left' && icon && (
                <div className={iconContainerClasses}>
                  <HeroIcon
                    type={iconType}
                    icon={icon as IconName}
                    extraClassNames={iconSize}
                    ariaLabel={labelIcon || label}
                  />
                </div>
              )}

              {iconPosition === 'custom' ? (
                <div className={iconContainerClasses}>
                  <HeroIcon type={iconType} icon={icon as IconName} ariaLabel={labelIcon || label} />
                </div>
              ) : (
                label && <span className={labelSize}>{label}</span>
              )}

              {iconPosition === 'right' && icon && (
                <div className={iconContainerClasses}>
                  <HeroIcon
                    type={iconType}
                    icon={icon as IconName}
                    extraClassNames={iconSize}
                    ariaLabel={labelIcon || label}
                  />
                </div>
              )}
            </>
          )}
        </Button>
      </Theme>
    );
  },
);

ButtonComponent.displayName = 'ButtonComponent';

export default ButtonComponent;
