import { twMerge } from 'tailwind-merge';
import { Helmet } from 'react-helmet';
import { useScreenSize } from '@hooks/useScreenSize';

import HistoryCard from '@components/atoms/history-card/history-card';
import { timelineData, ourHistoryTimelineContent } from '@utils/helper';

import styles from './style.module.css';

const OurHistorySection = () => {
  const isSmallScreen = useScreenSize();
  const sectionClasses = 'w-full text-brand-gray-80 bg-brand-light-20 mx-auto';
  const containerClasses = twMerge(isSmallScreen ? 'px-4 py-12' : 'p-20', styles.container);
  const titleClasses = twMerge(
    isSmallScreen ? 'mb-4 text-heading-small' : 'mb-10 text-heading-xxLarge',
    styles.sectionTitle,
  );
  const contentClasses = twMerge(isSmallScreen ? 'flex-col' : 'flex-row justify-between', styles.contentClasses);

  return (
    <section className={sectionClasses}>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(timelineData)}</script>
      </Helmet>
      <div className={containerClasses}>
        <h2 className={titleClasses}>Conheça nossa história</h2>
        <div className={contentClasses}>
          {ourHistoryTimelineContent.map((item, itemIndex) => (
            <HistoryCard key={itemIndex} item={item} index={itemIndex} historyItemsLength={ourHistoryTimelineContent.length} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurHistorySection;
