import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import HeroIcon from '@components/atoms/hero-icon';
import { LinksContent } from '@components/utils/helper';

import style from './style.module.css';

interface AccordionItemProps {
  title: string;
  content: string;
}

const AccordionItem: React.FC<AccordionItemProps> = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const scheduleDemoLink = LinksContent[1];
  const isSmallScreen = useScreenSize();

  const accordionItemClasses = twMerge(
    isOpen ? 'border-brand-primary-60 bg-brand-primary-60' : 'border-brand-primary-70 bg-brand-light-20',
    style.AccordionBtn,
  );
  const accordionContainer = twMerge(isSmallScreen ? 'mt-6 pb-4' : 'mt-12 pb-8', style.AccordionContainer);
  const accordionTitle = twMerge(
    isSmallScreen ? 'w-[18.0625rem] text-paragraph-large' : 'w-full text-heading-medium leading-9',
    style.AccordionTitle,
  );
  const accordionDescription = twMerge(isSmallScreen ? 'text-paragraph-small' : 'text-paragraph-large');

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={accordionContainer}>
      <button
        onClick={handleToggle}
        className="mb-1 flex w-full text-left items-center justify-between"
        aria-expanded={isOpen}
        aria-controls={`accordion-content-${title}`}
        id={`accordion-button-${title}`}
      >
        <h3 className={accordionTitle}>{title}</h3>
        <div className={`mb-1 ${accordionItemClasses}`}>
          <HeroIcon
            icon={isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'}
            type="solid"
            extraClassNames={twMerge(style.chevronIcon, isOpen ? 'text-brand-light-20' : 'text-brand-primary-70')}
          />
        </div>
      </button>
      {isOpen && (
        <div
          className={accordionDescription}
          id={`accordion-content-${title}`}
          aria-labelledby={`accordion-button-${title}`}
        >
          {title === 'Como a tecnologia da Clarke vai ajudar minha empresa?' ? (
            <p className={accordionDescription}>
              {content}
              <ul className="list-none pl-0 hover:font-regular">
                <li className="text-current no-underline hover:text-current hover:font-normal">
                  <strong>1. Marketplace para contratação de energia:</strong>
                  <br />
                  <span className={style.subItem}>
                    Ferramenta que conecta mais de 75 geradoras ou comercializadoras de energia ao cliente na disputa
                    pelo fornecimento de energia para sua empresa.
                  </span>
                </li>
                <li className="text-current no-underline hover:text-current hover:font-normal">
                  <strong>2. Plataforma do cliente:</strong>
                  <br />
                  <span className={style.subItem}>
                    Acompanhamento das etapas da migração e dashboard intuitiva com dados e relatórios de consumo,
                    economia e sustentabilidade.
                  </span>
                </li>
              </ul>
            </p>
          ) : title === 'Eu posso ser cliente da Clarke Energia?' ? (
            <p>
              {content}{' '}
              <a
                href={scheduleDemoLink.src}
                target={scheduleDemoLink.typeOfWindow}
                className="text-brand-primary-80 underline"
              >
                Para agendar uma reunião, clique aqui.
              </a>
            </p>
          ) : title === 'Como posso contratar os serviços da Clarke Energia?' ? (
            <p>
              {content}{' '}
              <a
                href={scheduleDemoLink.src}
                target={scheduleDemoLink.typeOfWindow}
                className="text-brand-primary-80 underline"
              >
                Para agendar uma reunião, clique aqui.
              </a>
            </p>
          ) : (
            <p>{content}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
