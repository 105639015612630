import { twMerge } from 'tailwind-merge';
import { useScreenSize } from '@hooks/useScreenSize';

import CustomersSection from '@components/molecules/customers-section';
import CustomersPains from '@components/molecules/customers-pains';
import RetailSection from '@components/molecules/retail-section';
import VideoSection from '@components/molecules/video-section';
import CompetitiveAdvantages from '@components/molecules/competitive-advantages';
import BannerSection from '@components/molecules/banner-section';
import FAQSection from '@components/molecules/faq-section';
import NewsletterSection from '@components/molecules/newsletter-section';
import ButtonComponent from '@components/atoms/button/button';
import MetaTags from '@components/atoms/meta-tags';

import thumbnailVideoCase from '@assets/thumbnail-video-case.png';

import videoCase from '@assets/video/apresentacaoCaseMigracao.mp4';
import captionCase from '@assets/video/captionApresentacaoCaseMigracao.vtt';
import { HeroContainer } from '@components/molecules/home/hero-container';
import EconomySimulator from '@components/molecules/economy-simulator';

function HomePage() {
  const isSmallScreen = useScreenSize();

  const handleLearnMoreClick = () => {
    window.open('/sobre-nos', '_blank');
  };

  const ctaButton = twMerge(
    isSmallScreen ? 'w-full text-left flex' : 'w-fit text-center',
    'gap-3 h-14 text-paragraph-large',
  );

  return (
    <section className="w-full overflow-x-hidden" aria-label="Página Inicial">
      <MetaTags
        title="Economize até 40% na conta de luz da sua empresa"
        description="A Clarke ajuda a sua empresa a economizar na conta de luz com o Mercado Livre de Energia. Compre energia limpa e mais barata com a gente!"
        canonicalLink="https://clarke.com.br/"
      />
      <HeroContainer isHomePath={true} />
      <CustomersSection />
      <CustomersPains />
      <RetailSection />
      <EconomySimulator />
      <VideoSection
        videoPath={videoCase}
        videoTitle="Mais de 30% de economia! Cliente Clarke expandiu sua fábrica após migrar para Mercado Livre"
        title="A Clarke acredita na democratização da energia"
        description="A primeira gestora independente do Mercado Livre de Energia no Brasil a trazer economia, transparência, segurança e previsibilidade no consumo de energia da sua empresa."
        descriptionMaxWidth="max-w-full"
        thumbnailPath={thumbnailVideoCase}
        captionPath={captionCase}
        uploadDate="2024-08-23"
        duration="PT4M00S"
      >
        <ButtonComponent
          icon="ArrowRightIcon"
          iconPosition="right"
          iconType="solid"
          iconStyles="text-brand-gray-80 w-5 h-5"
          kind="secondary"
          size="1"
          label="Saiba mais sobre nós"
          className={twMerge(ctaButton, 'mt-6 h-[2.8125rem]')}
          onClick={handleLearnMoreClick}
        />
      </VideoSection>
      <CompetitiveAdvantages />
      <BannerSection />
      <FAQSection />
      <NewsletterSection />
    </section>
  );
}

export default HomePage;
