import React, { useEffect, useState } from 'react';

interface FormProps {
  formStyle?: string;
}

const loadHubspotForm = (setFormLoaded: React.Dispatch<React.SetStateAction<boolean>>) => {
  const script = document.createElement('script');
  script.setAttribute('charset', 'utf-8');
  script.type = 'text/javascript';
  script.src = 'https://js.hsforms.net/forms/embed/v2.js';

  script.onload = () => {
    if ((window as any).hbspt) {
      (window as any).hbspt.forms.create({
        region: 'na1',
        portalId: '21312607',
        formId: '84a85fe9-3e0e-4eee-8220-ad81ff9293bc',
        target: '#hs-form',
      });
      setFormLoaded(true);
    }
  };

  script.onerror = () => {
    setFormLoaded(false);
  };

  document.body.appendChild(script);

  return () => {
    if (document.body.contains(script)) {
      document.body.removeChild(script);
    }
  };
};

const FormComponentHeroSection: React.FC<FormProps> = ({ formStyle }) => {
  const [formLoaded, setFormLoaded] = useState(false);

  useEffect(() => {
    const cleanup = loadHubspotForm(setFormLoaded);
    return cleanup;
  }, []);

  return (
    <div id="hs-form" className={formStyle}>
      {!formLoaded && <p>Carregando formulário...</p>}
    </div>
  );
};

export { FormComponentHeroSection };
